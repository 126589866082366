import { darken } from "polished"
import styled from "styled-components"
import { lg } from "mill/utils/breakpoints"
import zIndex from "shared/utils/zIndexLevels"

export const navHeight = "7rem"

export const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  a > img {
    margin-top: 1.4rem;
    height: 2.4rem;
  }
  @media ${lg} {
    height: 100%;
    margin-right: 1.5rem;
    a > img {
      margin-top: 0.1rem;
    }
    button {
      display: none;
    }
  }
`

export const NavigationContainer = styled.div`
  ${props =>
    !props.menuOpen &&
    `
  overflow: hidden;
  max-height: 0;
  `}

  ${props => props.menuOpen && `margin-top: 2rem;`}

  @media screen and ${lg} {
    flex: 1;
    max-height: none;
    overflow: unset;
    margin-top: 0;
    display: flex;
    justify-content: space-between;
  }
`

export const PrimaryNav = styled.div`
  @media screen and ${lg} {
    ul {
      display: flex;
      height: ${navHeight};
      align-items: center;
    }

    li {
      list-style: none;
    }

    a {
      padding: 1rem 2rem;
      border-radius: 9px;
      font-weight: 600;
      display: inline-block;
      margin-right: 1rem;
    }
  }
`
export const UtilityNav = styled.div`
  padding-top: 1.4rem;

  ul.utility {
    li {
      margin-right: 1rem;
    }
  }

  > ul {
    display: flex;
    li {
      font-size: 1.5rem;
    }
  }

  > a {
    text-decoration: none;
    display: block;
  }

  > p {
    font-size: 1.3rem;
    margin: 0;
    line-height: 2;

    br {
      display: none;
    }

    a {
      display: inline-block;
    }
  }

  @media screen and ${lg} {
    > ul.utility,
    > p {
      display: none;
    }

    padding-top: 0;
    align-items: center;

    > p {
      line-height: 1.7rem;
      display: none;
      br {
        display: inline;
      }
    }
  }
`

export const Container = styled.div`
  background: #2A4B55;
  color: white;
  position: sticky;
  min-height: 5rem;
  top: 0;
  z-index: ${zIndex.menu};

  ${props => props.menuOpen && `height: 100vh;`}
  @media screen and ${lg} {
    height: ${navHeight};

    nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
  }

  a {
    color: white;
  }

  a.active {
    font-weight: 600;
    color: ${props => props.theme.colors.primary};
  }
`

export const PrimaryNavigationItem = styled.li`
  a {
    font-size: 1.6rem;
    text-decoration: none;
    display: block;
    line-height: 5rem;
    border-bottom: 1px solid ${darken(0.03, "#2A4B55")};

    @media screen and ${lg} {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background 0.2s ease-in, color 0.2s ease-in;
      border-bottom: none;
      line-height: 1.4;
      &:hover,
      &.active {
        background: ${darken(0.03, "#2A4B55")};
        color: ${props => props.theme.colors.primary};
      }
    }
  }
`

export const Dropdown = styled.ul`
  position: fixed;
  top: 0;
  right: 0;

  > li {
    position: relative;
    height: 5rem;
    display: flex;
    align-items: center;
  }

  li:hover {
    ul {
      display: block;
      position: absolute;
      padding-top: 0rem;
      top: 7rem;

      right: 0;
      z-index: 10;
      min-width: 17rem;
      background: #2A4B55;
      border-radius: 0 0 3px 3px;
      margin: 0;
      padding-left: 0;

      li {
        margin-left: 0;
      }
    }
  }

  @media screen and ${lg} {
    position: static;

    display: flex !important;
    align-items: center;

    span {
      margin-right: 1rem;
    }
    > li {
      height: 7rem;
    }
  }
`

export const Subnav = styled.ul`
  margin: 0;
  padding: 0;

  @media screen and ${lg} {
    display: none;
    li {
      margin-right: 0;
      margin-left: 1rem;
    }
  }

  li a {
    font-size: 1.4rem;
    text-decoration: none;
    display: block;
    line-height: 5rem;
    border-bottom: 1px solid ${darken(0.03, "#2A4B55")};
    padding-right: 2rem;
    padding-left: 2rem;
    transition: all 0.2s ease-in;

    &:hover {
      background: ${darken(0.03, "#2A4B55")};
      color: ${props => props.theme.colors.primary};
    }
  }
`

export const NotificationPositioning = styled.div`
  position: relative;
  right: -1rem;
  margin-right: 3rem;
  margin-top: -1px;

  @media screen and ${lg} {
    display: none;
  }
`

export const HamburgerItem = styled.li`
  @media screen and ${lg} {
    display: none !important;
  }
`

export const DropdownItem = styled.li`
  display: none !important;
  margin: 0;
  margin-left: 0.5rem;
  padding: 0;

  > div {
    display: flex;
  }

  button {
    border: none;
    display: inline-block;
    background: ${darken(0.03, "#2A4B55")};
    border-radius: 1.2rem;
    height: 2.4rem;
    width: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
  }

  @media screen and ${lg} {
    display: flex !important;
  }
`
