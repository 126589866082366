import React from "react"
import Link from "shared/components/Link"
import { darken } from "polished"
import styled from "styled-components"
import { headingFont, navbarHeight } from "mill/utils/settings"
import { Container } from "mill/utils/shared/core"
import zIndex from "shared/utils/zIndexLevels"

const Navbar = styled.nav`
  background: ${darken(0.03, "#2A4B55")};
  position: sticky;
  height: ${navbarHeight};
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.menu};
  color: white;
  line-height: 1.2;
`

const Text = styled.div`
  height: ${navbarHeight};
  display: flex;
  align-items: center;

  a,
  a:hover,
  span {
    font-family: ${headingFont};
    font-size: 14px;
    font-weight: 400;
    color: white;
  }

  span {
    font-style: italic;
    opacity: 0.8;
  }
`

const BackToAdminBar = ({ additionalInfoText }) => {
  return (
    <Navbar>
      <Container>
        <Text>
          <Link data-qa="save-and-return" to="/admin/campaigns">
            Return to dashboard
            <span>{additionalInfoText && ` (${additionalInfoText})`}</span>
          </Link>
        </Text>
      </Container>
    </Navbar>
  )
}

export default BackToAdminBar
