import React, { useContext } from "react"
import Hamburger from "mill/components/Hamburger"
import NavLink from "shared/components/NavLink"
import Wrapper from "shared/components/Wrapper"
import Notifications from "shared/containers/Notifications"
import FeatureFlagsContext from "mill/utils/FeatureFlagsContext"
import {
  LogoContainer,
  NavigationContainer,
  PrimaryNav,
  UtilityNav,
  Subnav,
  Container,
  Dropdown,
  DropdownItem,
  HamburgerItem,
  PrimaryNavigationItem
} from "./styles"

const isInsights = (match, { pathname }) => {
  if (match) {
    return true
  }
  return pathname.startsWith("/admin/insights")
}

const isCampaigns = (match, { pathname }) => {
  if (match) {
    return true
  }
  return pathname.startsWith("/admin/campaigns")
}

const isQuestions = (match, { pathname }) => {
  if (match) {
    return true
  }
  return (
    pathname.startsWith("/admin/questions") ||
    pathname.startsWith("/admin/primer-questions")
  )
}

const isLearners = (match, { pathname }) => {
  if (match) {
    return true
  }
  return (
    pathname.startsWith("/admin/learners") ||
    pathname.startsWith("/admin/teams")
  )
}

const isLearningLibrary = (match, { pathname }) => {
  if (match) {
    return true
  }
  return pathname.startsWith("/admin/learning-library")
}

const primaryNavigationItems = [
  {
    key: "admin",
    path: "/admin",
    title: "Home"
  },
  {
    key: "insights",
    path: "/admin/insights/engagement",
    title: "Insights",
    isActive: isInsights
  },
  {
    key: "campaigns",
    path: "/admin/campaigns",
    title: "Campaigns",
    isActive: isCampaigns
  },
  {
    key: "questions",
    path: "/admin/questions",
    title: "Questions",
    isActive: isQuestions
  },
  {
    key: "learners",
    path: "/admin/learners",
    title: "Learners",
    isActive: isLearners
  },
  {
    key: "learning-library",
    path: "/admin/learning-library/articles",
    title: "Learning library",
    isActive: isLearningLibrary
  }
]

const getNavigationItems = (learningLibraryEnabled, isContentAdmin) => {
  let items = primaryNavigationItems

  if (!learningLibraryEnabled) {
    items = items.filter(i => i.key !== "learning-library")
  }

  if (isContentAdmin) {
    items = items.filter(
      i => !["admin", "insights", "campaigns", "learners"].includes(i.key)
    )
  }

  return items
}

// It's going to be best to shift the menu state and toggling out of this component.
// This way, we can lock the height on the body and prevent the UX scroll thats possible.
const AdminNavigation = ({ viewer, menuOpen, toggleMenu }) => {
  const {
    featureFlags: { learningLibraryEnabled }
  } = useContext(FeatureFlagsContext)
  const isContentAdmin = viewer.accountAdminAccessible && !viewer.accountAdmin
  const items = getNavigationItems(learningLibraryEnabled, isContentAdmin)

  return (
    <Container menuOpen={menuOpen}>
      <Wrapper>
        <nav>
          <LogoContainer>
            <NavLink end to={isContentAdmin ? "/admin/questions" : "/admin"}>
              <img
                alt="Yarno"
                srcSet="https://yarno.imgix.net/static/logo.png?h=32&dpr=1 1x,
                    https://yarno.imgix.net/static/logo.png?h=32&dpr=2 2x,
                    https://yarno.imgix.net/static/logo.png?h=32&dpr=3 3x"
                src="https://yarno.imgix.net/static/logo.png"
              />
            </NavLink>
          </LogoContainer>
          <NavigationContainer menuOpen={menuOpen}>
            <PrimaryNav>
              <ul>
                {items.map(item => {
                  return (
                    <PrimaryNavigationItem key={`nav-${item.title}`}>
                      <NavLink
                        end
                        className={({ isActive, isPending }) =>
                          isPending ? "pending" : isActive ? "active" : ""
                        }
                        to={item.path}>
                        {item.title}
                      </NavLink>
                    </PrimaryNavigationItem>
                  )
                })}
              </ul>
            </PrimaryNav>

            <UtilityNav>
              <ul className="utility">
                <li>
                  <NavLink end to="/">
                    Exit admin
                  </NavLink>
                </li>
                {viewer.accountAdmin && (
                  <li>
                    <NavLink end to="/admin/account">
                      Account settings
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink end to="/admin/profile">
                    Profile
                  </NavLink>
                </li>
              </ul>
              <p>
                Signed in as <br /> <strong>{viewer.nickname}</strong>
                <span>
                  {" "}
                  • <a href="/logout">Sign out</a>
                </span>
              </p>
              <Dropdown>
                {window.NOTIFICATIONS_ENABLED && (
                  <li>
                    <Notifications />
                  </li>
                )}
                <HamburgerItem>
                  <Hamburger
                    isFixed={false}
                    handleClick={toggleMenu}
                    isOpen={menuOpen}
                  />
                </HamburgerItem>
                <DropdownItem data-qa="admin-utility-nav">
                  <div>
                    <span>
                      <strong>{viewer.nickname}</strong>
                    </span>
                    <button>
                      <i className="fa fa-angle-down-regular" />
                    </button>
                  </div>
                  <Subnav data-qa="admin-utility-nav-list">
                    <li>
                      <NavLink end to="/">
                        Exit admin
                      </NavLink>
                    </li>
                    {viewer.accountAdmin && (
                      <li>
                        <NavLink end to="/admin/account">
                          Account settings
                        </NavLink>
                      </li>
                    )}
                    <li>
                      <NavLink end to="/admin/profile">
                        Profile
                      </NavLink>
                    </li>
                    <li>
                      <a href="/logout">Sign out</a>
                    </li>
                  </Subnav>
                </DropdownItem>
              </Dropdown>
            </UtilityNav>
          </NavigationContainer>
        </nav>
      </Wrapper>
    </Container>
  )
}

export default AdminNavigation
