import React from "react"
import { useLocation, useParams } from "react-router-dom"
import styled from "styled-components"
import { sm } from "mill/utils/breakpoints"

const FooterBackground = styled.div`
  overflow: hidden;
  ${props => props.$onAdminCampaignDashboard && `background: rgb(248,247,247);`}
`

const Footer = styled.footer`
  font-size: 1.4rem;
  border-top: 1px dashed #EAEAEA;
  margin-top: 1.5rem;
  padding-top: 2px;
  color: #B9B9B9;

  ul {
    border-top: 1px dashed #EAEAEA;
    list-style: none;
    margin: 0;
    padding: 1.5rem 0;
  }

  li {
    display: block;
    text-align: center;
    line-height: 1.8;
  }

  a {
    text-decoration: none;
    color: #B9B9B9;
    transition: all 0.2s ease-in;

    &:hover {
      color: ${props => props.theme.colors.primary}
    }
  }

  @media screen and ${sm} {
    ul {
      display: flex;
      justify-content: center;
    }

    li:first-of-type {
      &:before {
        content ""
      }
    }

    li {
      margin-right: 1rem;
      &:before {
        content: "•";
        padding-right: 1rem;
      }
    }
  }

`

const SupportFooter = () => {
  const location = useLocation()
  let { clusterId } = useParams()

  const uuidRegex = new RegExp(
    "^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$"
  )
  const isAdminCampaignDashboard =
    (location.pathname.startsWith("/admin") &&
      clusterId &&
      location.pathname.endsWith(`/${clusterId}`) &&
      uuidRegex.test(clusterId)) ||
    location.pathname.endsWith(`/question-drilldowns`) ||
    location.pathname.endsWith(`/learner-drilldowns`) ||
    location.pathname.endsWith(`/feedback`)

  const isQuestionScreen =
    (location.pathname.startsWith("/admin/questions") ||
      location.pathname.startsWith("/admin/primer-questions")) &&
    (location.pathname.endsWith("new") || location.pathname.endsWith("edit"))

  if (isQuestionScreen) {
    return null
  }

  return (
    <FooterBackground
      $onAdminCampaignDashboard={isAdminCampaignDashboard ? true : false}>
      <Footer>
        <ul>
          <li>
            <a
              href="https://status.yarno.com.au/"
              target="_blank"
              rel="noreferrer">
              Status
            </a>
          </li>
          <li>
            <a
              href="https://www.yarno.com.au/support"
              target="_blank"
              rel="noreferrer">
              Support
            </a>
          </li>
          <li>
            <a
              href="https://www.yarno.com.au/blog"
              target="_blank"
              rel="noreferrer">
              Blog
            </a>
          </li>
        </ul>
      </Footer>
    </FooterBackground>
  )
}

export default SupportFooter